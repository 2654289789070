//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-400:_3672,_880,_5760,_5256,_3072,_3252,_2552,_7888;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-400')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-400', "_3672,_880,_5760,_5256,_3072,_3252,_2552,_7888");
        }
      }catch (ex) {
        console.error(ex);
      }